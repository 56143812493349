.categoryTile {
  display: block;
  background: var(--hcColor-tile);
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--hcColor-tileBorder);
  transition: border-color 0.1s ease;
}

.categoryTile::after {
  content: "";
  display: table;
  clear: both;
}

.categoryTileLink {
  cursor: pointer;
}

.categoryTileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: var(--hcColor-tileIcon);
  border-radius: 8px;
  margin: 0 16px 0 0;
  font-size: 22px;
}

.categoryTileIcon:not(.categoryTileIconInline) {
  margin-bottom: 8px;
}

.categoryTileIconInline {
  float: left;
}

.categoryTileTitle {
  color: var(--hcColor-tileTitle);
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  padding: 7px 0 5px;
}

.categoryTileIconInline ~ .categoryTileTitleSingle {
  padding: 20px 0;
}

.categoryTileDescription {
  color: var(--hcColor-tileText);
  font-size: 16px;
  line-height: 1;
  margin: 6px 0;
}

.categoryTileTitle {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryTileDescription {
  height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryTileIconInline ~ .categoryTileDescription {
  white-space: nowrap;
  display: block;
  height: auto;
}

.categoryTileIconInline ~ .categoryTileTitleSingle ~ .categoryTileDescription {
  display: none;
}

.categoryTileLink:hover {
  border-color: #747474;
}
