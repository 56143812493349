.articleTile {
  background: var(--hcColor-tile);
  border: 1px solid var(--hcColor-tileBorder);
  display: block;
  border-radius: 8px;
  padding: 15px;
  min-height: 88px;
  overflow: hidden;
}

.articleTileHeader {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.articleTileHeaderOnly {
  padding-top: 16px;
}

.articleTileTitle {
  color: var(--hcColor-tileTitle);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 2em;
  margin: 0;
}

.articleTileDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--hcColor-tileText);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.articleTileLink:hover.articleTile {
  border-color: #747474;
}

.articleTileLinkChevron {
  float: right;
  margin-top: 17px;
  margin-left: 16px;
}

.articleTileCondensed {
  min-height: 65px;
}

.articleTileCondensed .articleTileHeader {
  margin-bottom: 0;
  padding: 1px 0 5px;
}

.articleTileCondensed .articleTileHeaderOnly {
  padding: 10px 0 0 0;
}

.articleTileCondensed .articleTileTitle {
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
  padding: 0;
}

.articleTileCondensed .articleTileLinkChevron {
  display: none;
}

.articleTileCondensed .articleTileDescription {
  font-size: 13px;
  line-height: 1;
}
